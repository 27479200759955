import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SimpleMaskMoneyService {

  private readonly args = {
    fixed: true,
    
    decimalSeparator: ',',
    thousandsSeparator: '.',
    emptyOrInvalid: '0,00',
    completer: '0'
  };

  public format = (value: any, fractionDigits: number): string => {
    return this.numberToText(this.textToNumber(value), fractionDigits);
  }

  public formatToNumber = (input: any, fractionDigits: number): number => {
    let retorno = '0';
    let value = this.textToNumber(input);

    if (!Number.isNaN(parseFloat(value))) {

      if (value.length <= fractionDigits) {
        value = this.formatDecimal(value, '0', '.', fractionDigits);
      } else {
        const lengthWithoutDecimals = value.length - fractionDigits;
        value = value.replace(new RegExp(`(\\d{${lengthWithoutDecimals}})(\\d{${fractionDigits}})`), '$1.$2');
      }

      retorno = value;

    }

    return parseFloat(retorno);
  }

  private onlyNumber = value => {
    let retorno = '';

    for (let i = 0; i < value.length; i++) {
      if (isFinite(value[i])) {
        retorno += value[i];
      }
    }

    return retorno;
  }

  private addingCompleterFromStart = (value, completer, fractionDigits) => {
    while (value.length < fractionDigits) {
      value = `${completer}${value}`;
    }
    return value;
  }

  private removingCompleterFromStart = (value, completer) => {
    while (value[0] === completer) {
      value = value.replace(completer, '');
    }
    return value;
  }

  private addingDecimalSeparator = (value, completer, separator, fractionDigits) => {
    const length = value.length - fractionDigits;
    const character = isFinite(completer) ? 'd' : 'w';

    let regexpFraction;
    let decimals = '$1';
    let dezenas = completer;

    regexpFraction = `(\\${character}{${fractionDigits}})`;
    if (length > 0) {
      regexpFraction = `(\\${character}{${length}})${regexpFraction}`;
      dezenas = decimals;
      decimals = '$2';
    }

    return value.replace(
      new RegExp(regexpFraction),
      `${dezenas}${separator}${decimals}`
    );
  }

  private addingHundredsSeparator(value: any, fractionDigits: number) {
    let size = value.length - fractionDigits;
    const hundreds = Math.ceil(size / 3);
    let regexpHundreds = '(\\d)';

    let replacement = `${this.args.decimalSeparator}$${hundreds + 1}`;

    for (let i = hundreds; i !== 0; i--) {
      if (size >= 3) {
        regexpHundreds = `(\\d{3})${regexpHundreds}`;
        size -= 3;
      } else {
        regexpHundreds = `(\\d{${size}})${regexpHundreds}`;
      }

      if (i > 1) {
        replacement = `${this.args.thousandsSeparator}$${i}${replacement}`;
      } else {
        replacement = `$${i}${replacement}`;
      }
    }

    return value.replace(new RegExp(regexpHundreds), replacement);
  }

  private removeSeparator = (value, separator) => {
    return value.replace(new RegExp(`\\${separator}`, 'g'), '');
  }

  private formatDecimal = (value, completer, separator, fractionDigits) => {
    value = this.addingCompleterFromStart(value, completer, fractionDigits);
    value = this.addingDecimalSeparator(value, completer, separator, fractionDigits);
    return value;
  }

  private textToNumber = input => {
    let retorno = input.toString();
    retorno = this.removeSeparator(retorno, this.args.thousandsSeparator);
    retorno = this.removeSeparator(retorno, this.args.decimalSeparator);
    retorno = this.onlyNumber(retorno);
    retorno = this.removingCompleterFromStart(retorno, this.args.completer);
    return retorno || '0';
  }

  private numberToText(input: any, fractionDigits: number) {
    let retorno = this.args.emptyOrInvalid;
    if (!Number.isNaN(parseFloat(input))) {
      if (input.length <= fractionDigits) {
        retorno = this.formatDecimal(input, '0', this.args.decimalSeparator, fractionDigits);
      } else {
        retorno = this.addingHundredsSeparator(input, fractionDigits);
      }
    }

    return retorno;
  }

}
