import { Component, OnInit, Input, Output, EventEmitter, forwardRef, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, AbstractControl, ValidationErrors } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SwitchComponent),
    multi: true
};

@Component({
  selector: 'siconv-switch',
  template: `<ui-switch size="small" [(ngModel)]="value" (change)="onChange($event)"></ui-switch> 
`,
  styles: [``],
  providers: [CUSTOM_SWITCH_CONTROL_VALUE_ACCESSOR]
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  
  @Input('isChecked') isChecked: boolean = false;    
  @Input() disabled: boolean = false;

  ngControl: NgControl;

  constructor(
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  @Output() switchChange: EventEmitter<any> = new EventEmitter<any>();

  //Placeholders for the callbacks which are later providesd by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public onChange(vl: any): void {    
    this.isChecked = vl;
    this.switchChange.emit(this.isChecked);
  }

  //get accessor
  get value(): any {
    return this.isChecked;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
      if (v !== this.isChecked) {
          this.isChecked = v;
          this.onChangeCallback(v);
      }
  }

  writeValue(value: any) {
    if (value !== this.isChecked) {
      this.isChecked = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }  

  setDisabledState(isDisabled: boolean): void {         
    this.disabled = isDisabled;
  }

  onBlur() {
    this.onTouchedCallback();    
  }
}
