import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { User } from '../../model/user.model';
import { SimpleTimer } from 'ng2-simple-timer';

@Component({
  selector: 'siconv-top',
  template: `<nav class="navbar navbar-light bg-light navbar-topo">
  <div class="container-fluid">
    <div class="navbar-header">        
      <a class="navbar-brand" [routerLink]="logoRoute">
        <img [src]="logoUrl" alt="SICONV" style="height: 70px;">  
      </a>        
    </div>
    <ul class="nav navbar-nav titulo-topo">        
        <li>{{appName}}</li>
    </ul>    
    <ul class="nav navbar-nav navbar-right">
      <div class="clock">
        <span class="fa fa-clock-o relogio"></span>
        {{minutos | zeroEsquerdaMask}}:{{segundos | zeroEsquerdaMask}}
        <span class="fa fa-envelope-o envelope"></span>                    
      </div>
      <li class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
          <span class="fa fa-user nome-usuario"></span>{{user.name}}
          <span class="fa fa-chevron-down seta-usuario"></span>
        </a>
        <ul class="dropdown-menu">
          <li><a href="#" (click)="profileUpdate()">Alterar Meus Dados</a></li>
          <li><a href="#" (click)="passwordUpdate()">Alterar Senha</a></li>
          <li>
            <a href="#" (click)="logoutUser()">Sair do Sistema<span class="fa fa-times-circle sair"></span></a>            
          </li>
        </ul>
      </li>      
    </ul>
  </div>
</nav>`,
  styles: [`.navbar{padding:0}.navbar-topo{margin-bottom:0;height:56px;border:1px solid #ebebeb;border-radius:0;padding-top:1px}.navbar-topo .container-fluid{padding-left:34px;align-items:unset;justify-content:space-between}.navbar-topo .container-fluid .navbar-header .navbar-brand{padding-top:0;margin-top:-5px}.navbar-topo .container-fluid .titulo-topo{font-family:OpenSans-Regular,sans-serif;font-size:18px;color:#454546;margin-top:15px}.navbar-right{flex-direction:row;min-width:405px}.navbar-right .clock{float:left;color:#454546;font-family:OpenSans-Regular,sans-serif;font-size:11px;font-weight:700;letter-spacing:3px}.navbar-right .relogio{font-size:16px;padding-top:18px}.navbar-right .envelope{font-size:18px;color:#0069ab;padding-top:19px;margin-left:50px;margin-right:5px}.navbar-right .dropdown{top:3px;margin-top:14px;margin-left:15px}.navbar-right .dropdown .dropdown-toggle{font-family:OpenSans-Regular,sans-serif;font-size:14px;color:#454546;margin-right:12px;font-weight:300}.navbar-right .dropdown .dropdown-toggle .nome-usuario{margin-right:8px;font-size:18px}.navbar-right .dropdown .dropdown-toggle .seta-usuario{margin-left:7px}.navbar-right .dropdown .dropdown-toggle:after{display:none}.navbar-right .dropdown .dropdown-menu{border-right:4px solid #cbd2ce;border-bottom:1px solid #cbd2ce;margin-top:-16px}.navbar-right .dropdown .dropdown-menu li a{color:#454546;padding:15px 50px;font-family:OpenSans-Regular,sans-serif;font-size:14px;display:block}.navbar-right .dropdown .dropdown-menu li a:hover{background:#ececec}.navbar-right .dropdown .dropdown-menu li .sair{color:#0a6aa6;position:relative;left:30px}.navbar-nav .dropdown-menu{position:absolute;width:250px}`],
})
export class TopComponent implements OnInit, OnDestroy {

  @Input() appName: string;
  @Input() logoRoute: string[] = ['/'];
  @Input() logoUrl: string = './assets/img/logo-portal.png';

  @Input() user: User;
  @Input() passwordUrl: string;
  @Input() profileUrl: string;
  @Output() logout = new EventEmitter();

  @Input() totalTime: number;
  @Input() warningTime: number;
  @Output() sessionTime = new EventEmitter();

  dthora: string;
  minutos: number;
  segundos: number;
  timerId: string;

  constructor( private st: SimpleTimer ) { }

  public ngOnInit(): void {
    this.getDateHour();
    this.st.newTimer('1sec', 1);
    this.timerId = this.st.subscribe('1sec', () => this.callbackSeg());
    this.minutos = this.totalTime - 1;
    this.segundos = 60;
  }

  ngOnDestroy(): void {
    this.delAllTimer();
  }

  getDateHour() {
    this.dthora = new Date().toString();
  }

  callbackSeg() {
    this.segundos--;
    if (this.segundos === -1) {
      this.minutos--;
      this.segundos = 59;
    }

    if (this.minutos < 0) {
      this.delAllTimer();
      //A sessÃ£o encerrou. SerÃ¡ preciso logar novamente.
      this.sessionTime.emit('expired');
    }

    if (this.minutos === this.warningTime && this.segundos === 0) {
      //Esta sessÃ£o irÃ¡ se encerrar em menos x minutos.
      this.sessionTime.emit('warning');
    }
  }

  delAllTimer() {
    this.minutos = 0;
    this.segundos = 0;
    this.st.delTimer('1sec');
  }

  profileUpdate(){
    window.location.href = this.profileUrl;
  }

  passwordUpdate(){
    window.location.href = this.passwordUrl;
  }

  logoutUser(){
    this.logout.emit(true);
  }
}
