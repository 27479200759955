import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SwitchComponent } from './components/switch/switch.component';
import { CommonModule } from '@angular/common';

import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
  imports: [
    CommonModule, 
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SwitchComponent
  ],
  exports: [
    SwitchComponent
  ] 
})
export class SiconvSwitchModule {}
