import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import "rxjs/add/operator/filter";
import { ActivatedRoute, Router, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';

export interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: 'siconv-breadcrumbs',
  template: `<ol class="breadcrumb hidden-xs">
  <a [routerLink]="home"><span class="fa fa-home home"></span></a>
  <li *ngFor="let breadcrumb of breadcrumbs;">
    <a *ngIf="breadcrumb.params" [routerLink]="[breadcrumb.url, breadcrumb.params]">&nbsp;/&nbsp;{{ breadcrumb.label }}</a>
    <a *ngIf="!breadcrumb.params" class="active" [routerLink]="[breadcrumb.url]">&nbsp;/&nbsp;{{ breadcrumb.label }}</a>
  </li>
</ol>
`,
  styles: [`.breadcrumb{background:#ececec;font-size:13px;font-family:OpenSans-Regular,sans-serif;height:30px;width:100%;padding-left:40px;padding-top:0;padding-bottom:0;color:#0a6aa6;letter-spacing:.075px;display:flex;align-content:center;align-items:center}.breadcrumb .home{padding-right:5px;color:#0a6aa6;font-size:18px}.breadcrumb a{color:#0a6aa6}.breadcrumb span{color:#454546}ol{margin-bottom:0}`],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  @Input() home: string = '';

  public breadcrumbs: IBreadcrumb[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit(): void {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
    const ROUTE_DATA_LABEL: string = "label";

    //subscribe to the NavigationEnd event
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string="", breadcrumbs: IBreadcrumb[]=[]): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
    const ROUTE_DATA_LABEL: string = "label";

    //get the child routes
    let children: ActivatedRoute[] = route.children;    

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }    

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");   
    
      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };

      if (!child.snapshot.data[ROUTE_DATA_BREADCRUMB]) {                
        breadcrumb = {                  
          label: child.snapshot.data[ROUTE_DATA_LABEL],
          params: null,
          url: ''
        };        
      }   
      
      breadcrumbs.push(breadcrumb);    

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public isHome(breadcrumb): boolean {
    return breadcrumb.label.toUpperCase() == 'HOME';
  }
}