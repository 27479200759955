import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '../../model/user.model';
import { MenuDomain } from '../../model/menu.model';

@Component({
  selector: 'siconv-header',
  template: `<div class="header">
   <!-- Topo -->
  <siconv-top
    [appName]="appName"
    [logoUrl]="logoUrl"
    [logoRoute]="logoRoute"
    [user]="user"
    [passwordUrl]="passwordUrl"
    [profileUrl]="profileUrl"
    (logout)="logoutFeedback($event)"
    [totalTime]="totalTime"
    [warningTime]="warningTime"
    (sessionTime)="sessionTimeFeedback($event)">
  </siconv-top>
  <!-- Menu -->
  <siconv-menu
    [menu]="menu">
  </siconv-menu>
  <!-- Breadcrumb -->
  <siconv-breadcrumbs [home]="home"></siconv-breadcrumbs>
</div>`,
})
export class HeaderComponent implements OnInit {

  @Input() appName: string;
  @Input() logoRoute: string[] = ['/'];
  @Input() logoUrl: string = './assets/img/logo-portal.png';

  @Input() user: User;
  @Input() passwordUrl: string;
  @Input() profileUrl: string;
  @Output() logout = new EventEmitter();

  @Input() totalTime: number;
  @Input() warningTime: number;
  @Output() sessionTime = new EventEmitter();

  @Input() menu: MenuDomain;
  @Input() home: string = '';

  constructor() {
  }

  public ngOnInit(): void {
  }

  sessionTimeFeedback(topSessionTime) {
    this.sessionTime.emit(topSessionTime);
  }

  logoutFeedback(topLogout){
    this.logout.emit(true);
  }
}
